/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AnimationLoading from 'assets/animations/loading.json';
import { formatCurrencyBRL } from 'utils/formatCurrencyBRL';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { useDateLogin } from 'hooks/useDateLogin';
import SpecificApiLocalClient from 'services/api';
import Modal from 'components/Modal';
import { asyncLocalStorage, getStorage } from 'services/storage';

import { MdPix, MdContentCopy } from 'react-icons/md';
import apiAutoatendimento from 'services/apiAutoatendimento';

import { useRoutes } from 'hooks/useRoutes';
import { useHistory } from 'react-router';
import Lottie from 'react-lottie';
import {
  AnimationContainer,
  Container,
  ContentCenter,
  ContainerCards,
  ContainerCardsSingle,
  Card,
  CardDelivery,
  CardModal,
  ContainerCardsFooter,
  ContainerLoading,
} from './styles';

interface ListProducts {
  CD_ITEM: number;
  DS_ITEM: string;
  QT_VENDA_ITEM: number;
  VL_VENDA_ITEM_TOTAL: number;
}

const Command: React.FC = () => {
  const [listProducts, setListProducts] = useState<ListProducts[]>([]);
  const { companyData } = useInformationsClient();
  const { userLoginData } = useDateLogin();
  const api = SpecificApiLocalClient();
  const [command, setCommand] = useState('');
  const [showConfModal, setShowConfModal] = useState(false);
  const { updateRoute } = useRoutes();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Carregando conferência...');
  const [cellPhone, setCellPhone] = useState('Não informado');
  const [idDm084, setIdDm084] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [deliveryTax, setDeliveryTax] = useState(0);
  const [itensTotal, setItensTotal] = useState(0);

  const [seePix, setSeePix] = useState(false);
  const [dataPixInfo, setDataPixInfo] = useState([]);

  const getProductsDelivery = async (value: number) => {
    const products = await api.get(
      `/fprg_ConferenciaItensDeliveryJSON/${companyData?.companyCode}/${value}`,
    );
    const orderProducts = products.data.result[0];
    const activeOrderProducts = orderProducts.filter(val => {
      return val.IN_SITUACAO === 'A';
    });
    setListProducts(activeOrderProducts);
  };

  const getProducts = async () => {
    const responseCommand = await api.get(
      `/fprg_IncluirContaVerificarPessoaJSON/${companyData.command}/${companyData.table}/${companyData.companyCode}/1/0/`,
    );
    const prjMsg = String(responseCommand.data.result[0].msg);
    if (prjMsg.includes('Conta já conferida!')) {
      setCommand('Conta conferida');
    } else {
      setCommand('Faça seu pedido');
    }
    const response = await api.get(
      `/fprg_ConferenciaIDJSON/${companyData.companyCode}/${companyData.command}/C/0/`,
    );
    if (response.data.result[0][0] !== undefined) {
      const { ID_DM006 } = response.data.result[0][0];
      const products = await api.get(
        `/fprg_ConferenciaItensJSON/${companyData.companyCode}/${ID_DM006}/0/`,
      );
      const orderProducts = products.data.result[0];
      const activeOrderProducts = orderProducts.filter(val => {
        return val.IN_SITUACAO === 'AB';
      });
      setListProducts(activeOrderProducts);
    }
  };

  const getStatusDelivery = async value => {
    const answer = await api.get(
      `/fprg_GetStatusContaDeliveryByJSON/${companyData.companyCode}/${value}`,
    );
    return answer.data.result[0][0];
  };

  const getValoresDelivery = async value => {
    const answer = await api.get(
      `/fprg_GetValoresDeliveryJSON/${companyData.companyCode}/${value}`,
    );
    return answer.data.result[0][0];
  };

  const closeCommand = () => {
    api
      .get(
        `/fprg_ImprimirConferenciaJSON/${companyData.command}/${companyData.printerCode}/${companyData.companyCode}/0/`,
      )
      .then(response => {
        setShowConfModal(false);
        setisLoading(false);
        toast.success('Consumo encerrado com sucesso!', {
          onClose: () => {
            updateRoute('/command');
            history.push('/command');
          },
        });
      });
  };

  const updateStatusDelivery = async (id: number) => {
    getStatusDelivery(id).then(statusValue => {
      try {
        const dtPedido = statusValue.DT_PEDIDO;
        const dtSaida = statusValue.DT_SAIDA;
        const dtEntrega = statusValue.DT_ENTREGA;
        const dtCancelamento = statusValue.DT_CANCELAMENTO;
        const nrSequencia = statusValue.NR_SEQUENCIA_DIA;
        if (dtCancelamento !== '') {
          setCommand('Pedido cancelado!');
          setUpdateStatus(false);
        } else if (dtEntrega !== '') {
          setCommand('Pedido entregue!');
          setUpdateStatus(false);
        } else if (dtSaida) {
          setCommand('Pedido em rota de entrega!');
        } else if (nrSequencia !== 0) {
          setCommand('Pedido confirmado!');
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao buscar status do pedido!');
      }
    });
  };

  useEffect(() => {
    if (
      companyData.setDelivery === 'S' &&
      companyData.setDeliveryTable === companyData.table
    ) {
      setCommand('Pedido em aberto!');
      asyncLocalStorage.getItem('cellPhone').then(value => {
        const tmpCellPhone = value.replaceAll('"', '');
        let formatedPhone;
        if (tmpCellPhone.length === 11) {
          formatedPhone = tmpCellPhone.match(/(\d{2})(\d{5})(\d{4})/);
          setCellPhone(
            `(${formatedPhone[1]}) ${formatedPhone[2]}-${formatedPhone[3]}`,
          );
        } else if (tmpCellPhone.length === 9) {
          formatedPhone = tmpCellPhone.match(/(\d{5})(\d{4})/);
          setCellPhone(`${formatedPhone[1]}-${formatedPhone[2]}`);
        } else if (tmpCellPhone.length === 10) {
          formatedPhone = tmpCellPhone.match(/(\d{2})(\d{8})(\d{4})/);
          setCellPhone(
            `(${formatedPhone[1]}) ${formatedPhone[2]}-${formatedPhone[3]}`,
          );
        } else {
          formatedPhone = tmpCellPhone.match(/(\d{4})(\d{4})/);
          setCellPhone(`${formatedPhone[1]}-${formatedPhone[2]}`);
        }

        if (value === '') {
          toast.error('Erro ao buscar status do pedido!');
        } else {
          const query = {
            _parameters: [
              [
                {
                  NR_FONE: value.replaceAll('"', ''),
                },
              ],
            ],
          };
          api
            .post(
              `%22fprg_GetIdPedidoDeliveryJSON%22/${companyData.companyCode}/`,
              query,
            )
            .then(answer => {
              const tmpIdDm084 = answer.data.result[0][0].ID;
              setIdDm084(tmpIdDm084);
              getProductsDelivery(tmpIdDm084).then(() => {
                updateStatusDelivery(tmpIdDm084).then(() => {
                  setUpdateStatus(true);
                  setisLoading(false);
                });
              });
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    } else {
      if (companyData.cellPhone.length === 11) {
        const tmpCellPhone = companyData.cellPhone.match(
          /(\d{2})(\d{5})(\d{4})/,
        );
        setCellPhone(
          `(${tmpCellPhone[1]}) ${tmpCellPhone[2]}-${tmpCellPhone[3]}`,
        );
      }

      getProducts().then(() => {
        setisLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyData.table,
    companyData.companyCode,
    companyData.command,
    showConfModal,
    cellPhone,
  ]);

  useEffect(() => {
    if (
      companyData.setDelivery === 'S' &&
      companyData.table === companyData.setDeliveryTable
    ) {
      getValoresDelivery(idDm084).then(data => {
        if (data.result[0] !== undefined) {
          const totalValue = data.result[0].VL_TOTAL;
          const totalValueItens = data.result[0].VL_TOTAL_ITENS;
          const deliveryValue = data.result[0].VL_ENTREGA;
          setDeliveryTax(deliveryValue);
          setItensTotal(totalValueItens);
          setSubTotal(totalValue);
        }
      });
    } else {
      const tmpSubtotal = listProducts.reduce((acc, val) => {
        return Number(acc) + Number(val.VL_VENDA_ITEM_TOTAL);
      }, 0);
      setSubTotal(tmpSubtotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDm084, listProducts]);

  useEffect(() => {
    const interval =
      updateStatus &&
      setInterval(() => {
        updateStatusDelivery(idDm084);
      }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDm084, updateStatus]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
  };

  useEffect(() => {
    if (seePix && dataPixInfo.length === 0) {
      const dataPix = {
        IN_TIPO_CHAVEPIX: JSON.parse(getStorage('tpPix')),
        DS_CHAVEPIX: JSON.parse(getStorage('keyPix')),
        NM_BENEFICIARIOPIX: JSON.parse(getStorage('nmPix')),
        NM_CIDADEPIX: JSON.parse(getStorage('cityPix')),
        VL_PIX: !companyData.tip ? subTotal : subTotal + subTotal * 0.1,
        CD_CLIENTE: companyData.companyCode,
      };

      apiAutoatendimento.post('/pix_qrcode/', dataPix).then(response => {
        if (response.status === 200) {
          setDataPixInfo(response.data);
        } else {
          toast.warning('Erro ao buscar informações do PIX!');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seePix]);

  const handleCopy = () => {
    navigator.clipboard
      // @ts-ignore
      .writeText(dataPixInfo.payload)
      .then(() => {
        toast.success('PIX Copia e Cola na área de transferência!');
      })
      .catch(err => {
        toast.error('Erro ao copiar texto: ', err);
      });
  };

  return (
    <>
      {isLoading ? (
        <ContainerLoading>
          <Lottie
            options={defaultOptions}
            style={{ width: '100%', height: 400 }}
          />
          <h1>{loadingText}</h1>
        </ContainerLoading>
      ) : (
        <AnimationContainer>
          <Container>
            <ContentCenter>
              <header>
                <p className="center-text">
                  {companyData.setDelivery === 'S' &&
                  companyData.table === companyData.setDeliveryTable ? (
                    <>
                      <span>Conferência do pedido </span>
                    </>
                  ) : (
                    <>
                      <span>Conferência da mesa </span>
                      <span>&nbsp;&nbsp;</span>
                      {companyData.table < 10 ? (
                        <span>0{companyData.table}</span>
                      ) : (
                        <span>{companyData.table}</span>
                      )}
                    </>
                  )}
                </p>
                <div>
                  <p>
                    <span>Data:</span> {userLoginData.day}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>Hora:</span> {userLoginData.hour}
                  </p>
                  {companyData.setDelivery !== 'N' &&
                    companyData.table !== companyData.setDeliveryTable && (
                      <>
                        {companyData.setPhone !== 'C' ? (
                          <p>
                            <span>Comanda:</span> {companyData.command}
                          </p>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  <p>
                    <span>Celular:</span> {cellPhone}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>Status:</span> {command}
                  </p>
                </div>
              </header>
              <main>
                <table>
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Quantidade</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listProducts.map(product => (
                      <tr
                        key={product.CD_ITEM + Math.floor(Math.random() * 100)}
                      >
                        <td>{product.DS_ITEM}</td>
                        <td>{product.QT_VENDA_ITEM}</td>
                        <td className="table-price">
                          {formatCurrencyBRL(product.VL_VENDA_ITEM_TOTAL)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </main>
              <footer>
                <ContainerCardsFooter>
                  {companyData.setDelivery === 'S' &&
                  companyData.table === companyData.setDeliveryTable ? (
                    <>
                      <div id="alignment">
                        <ContainerCardsSingle>
                          <CardDelivery>
                            <br />
                            <p>Subtotal: {formatCurrencyBRL(itensTotal)}</p>
                            <p>
                              Taxa de entrega: {formatCurrencyBRL(deliveryTax)}
                            </p>
                            <br />
                            <p>
                              <b>
                                Total do pedido: {formatCurrencyBRL(subTotal)}
                              </b>
                            </p>
                            <br />
                          </CardDelivery>
                        </ContainerCardsSingle>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="alignment">
                        {!companyData.tip ? (
                          <ContainerCardsSingle>
                            <Card>
                              <br />
                              <p>Total: {formatCurrencyBRL(subTotal)}</p>
                              <br />
                            </Card>
                          </ContainerCardsSingle>
                        ) : (
                          <></>
                        )}
                        {companyData.tip && companyData.mandatoryTipTax ? (
                          <>
                            <ContainerCardsSingle>
                              <Card>
                                <p>Subtotal: {formatCurrencyBRL(subTotal)}</p>
                                <p>
                                  Taxa de Serviço:{' '}
                                  {formatCurrencyBRL(subTotal * 0.1)}
                                </p>
                                <p>
                                  Total:{' '}
                                  {formatCurrencyBRL(subTotal + subTotal * 0.1)}
                                </p>
                              </Card>
                            </ContainerCardsSingle>
                          </>
                        ) : (
                          <></>
                        )}
                        {companyData.tip && !companyData.mandatoryTipTax ? (
                          <>
                            <ContainerCards>
                              <Card>
                                <p>Subtotal: {formatCurrencyBRL(subTotal)}</p>
                                <p>
                                  Taxa de Serviço:{' '}
                                  {formatCurrencyBRL(subTotal * 0.1)}
                                </p>
                                <p>
                                  Total:{' '}
                                  {formatCurrencyBRL(subTotal + subTotal * 0.1)}
                                </p>
                              </Card>
                              <Card>
                                <p>Total sem taxa de serviço: </p>
                                {formatCurrencyBRL(subTotal)}
                              </Card>
                            </ContainerCards>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </ContainerCardsFooter>
                <>
                  <br />
                  {companyData.table !== companyData.setDeliveryTable && (
                    <button
                      type="button"
                      className="button-filled button-footer"
                      onClick={() => {
                        setShowConfModal(true);
                      }}
                    >
                      <p>Encerrar consumo</p>
                    </button>
                  )}
                </>
              </footer>
              <CardModal>
                <div id="modalContent">
                  <Modal
                    visible={showConfModal}
                    setVisible={setShowConfModal}
                    maxHeight={30}
                    maxWidth={20}
                  >
                    {getStorage('keyPix').length > 2 && (
                      <div className="qrPix">
                        <div className="btnInfos">
                          <button
                            type="button"
                            onClick={() => {
                              setSeePix(!seePix);
                            }}
                          >
                            <MdPix /> {!seePix ? 'Ver' : 'Ocultar'} PIX
                          </button>
                          <p style={{ textAlign: 'right' }}>
                            Total: &nbsp;
                            <strong>
                              {!companyData.tip
                                ? formatCurrencyBRL(subTotal)
                                : formatCurrencyBRL(subTotal + subTotal * 0.1)}
                            </strong>
                          </p>
                        </div>
                        {seePix && (
                          <div className="infoPix">
                            <h4>Pagamento via PIX</h4>
                            <div className="qrcodePix">
                              <img
                                src={`data:image/png;base64,${
                                  // @ts-ignore
                                  dataPixInfo.qrcode
                                }`}
                                alt="Erro ao carregar QRCode"
                              />
                            </div>
                            <p className="pixText" style={{ marginTop: '0px' }}>
                              Escaneie o QR Code <br /> ou <br />
                              use o PIX Copia e Cola:
                              <br />
                            </p>
                            <div className="copyPix">
                              <input
                                onClick={handleCopy}
                                value={
                                  // @ts-ignore
                                  dataPixInfo.payload
                                }
                              />
                              <MdContentCopy
                                onClick={handleCopy}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <br />
                    <br />
                    <h2 className="text-modal">Deseja encerrar o consumo?</h2>
                    <div className="buttons-modal-command">
                      <button
                        type="button"
                        className="button-filled"
                        onClick={() => {
                          setLoadingText('Encerrando consumo da conta...');
                          setisLoading(true);
                          closeCommand();
                        }}
                      >
                        Sim
                      </button>
                      <button
                        type="button"
                        className="button-outline"
                        onClick={() => {
                          updateRoute('/');
                          history.push('/');
                        }}
                      >
                        Não
                      </button>
                    </div>
                  </Modal>
                </div>
              </CardModal>
            </ContentCenter>
          </Container>
        </AnimationContainer>
      )}
    </>
  );
};

export default Command;
